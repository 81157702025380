import {ChangeDetectionStrategy, Component, ElementRef, inject, OnInit} from '@angular/core';
import {ButtonComponent} from '@scpc/modules/common/components/button/button.component';
import {AbstractSwiperComponent} from '@scpc/modules/lobby/components/abstract.swiper.component';
import {Swiper} from 'swiper';
import {Bookmaker, Bookmakers, Country} from '@scpc/modules/common/services/cms.service';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {AsyncPipe} from '@angular/common';
import {GoogleTagManagerService} from '@scpc/modules/common/services/analytics/google-tag-manager.service';

@Component({
  selector: 'scp-bookmakers',
  templateUrl: './bookmakers.component.html',
  styleUrls: ['./bookmakers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    AsyncPipe,
  ],
})
export class BookmakersComponent extends AbstractSwiperComponent implements OnInit {

  private elementRef: ElementRef = inject(ElementRef);
  private dialogRef: DialogRef<Bookmakers> = inject(DialogRef);
  private googleTagManagerService: GoogleTagManagerService = inject(GoogleTagManagerService);
  private data: {
    bookmakers: Bookmakers;
    siteName: string;
  } = inject(DIALOG_DATA);

  protected siteName: string;
  protected bookmakers: Bookmaker[];
  protected country: Country;

  public ngOnInit(): void {
    this.bookmakers = this.data.bookmakers.bookmakers;
    this.country = this.data.bookmakers.country;
    this.googleTagManagerService.event('bookmakers', 'show', this.country.name);
    this.siteName = this.data.siteName;
    this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('.swiper') as HTMLElement, {
      loop: this.bookmakers.length > 1,
      slidesPerView: 1,
      resizeObserver: true,
      updateOnWindowResize: true,
      watchOverflow: true,
      on: {...this.on},
    });
  }

  protected close(): void {
    this.dialogRef.close();
    this.googleTagManagerService.event('bookmakers', 'close', this.country.name);
  }

  protected selected(bookmaker: Bookmaker): void {
    this.dialogRef.close();
    this.googleTagManagerService.event('bookmakers', 'selected', this.country.name, bookmaker.name);
    window.location.href = bookmaker.url;
  }

}
