import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { MatListItem } from '@angular/material/list';

@Component({
  selector: 'scp-dashboard-menu-item',
  templateUrl: './dashboard-menu-item.component.html',
  styleUrls: ['./dashboard-menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, RouterLinkActive, TranslatePipe, MatListItem],
})
export class DashboardMenuItemComponent {

  @Input()
  public icon: string;

  @Input()
  public url: string;

  @Input()
  public queryParams: {
    [key: string]: string | string[];
  };

  @Input()
  public title: string;

  @Input()
  public count = 0;

  @Input()
  public iconSize: {
    width: number,
    height: number
  };

}
