import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AuthenticationService, LayoutService, StorageService } from '@scpc/modules/common/services';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { Router, RouterLink } from '@angular/router';
import { ScpTour, ScpTourService } from '@scpc/modules/common/services/scp.tour.service';
import { InboxService } from '@scpc/modules/common/services/inbox.service';
import { Balance, InboxMessage } from '@scpc/dto';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { delay, first, takeUntil } from 'rxjs/operators';
import { AsyncPipe, DatePipe, isPlatformBrowser, NgStyle } from '@angular/common';
import { interval } from 'rxjs';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';
import { Customer } from '@scpc/dto/customer';
import { TranslatePipe } from '@ngx-translate/core';
import { CartButtonComponent } from '@scpc/modules/cart/components/cart-button/cart-button.component';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';
import { ProductsComponent } from '@scpc/modules/common/components/products/products.component';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import {TourAnchorTippyDirective, TourTippyModule} from '@scpc/modules/common/tour/tippy-tour.module';
import { ChatConfig } from '@scpc/dto/settings';
import { FormatMoneyPipe } from '@scpc/modules/common/pipes/format-money.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isOpenMode } from '@scpc/utils/chat.utils';

interface SupportChatConfig {
  icon: string,
  name: string,
  width: number,
  height: number,
  class: string,
  config: ChatConfig
}

@Component({
  selector: 'scp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    AsyncPipe,
    DatePipe,
    RouterLink,
    TranslatePipe,
    CartButtonComponent,
    ButtonComponent,
    ProductsComponent,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatIconButton,
    MatMenuTrigger,
    MatDivider,
    TourTippyModule,
    FormatMoneyPipe,
    NgStyle,
    TourAnchorTippyDirective,
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnChanges {

  @Output()
  public toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public menuButtonHidden = true;

  @Input()
  public customer: Customer;

  @Input()
  public balance: Balance;

  @Input()
  public tours: ScpTour[] = [];

  @Input()
  public cart = false;

  @Output()
  public tour: EventEmitter<ScpTour> = new EventEmitter<ScpTour>();

  @Output()
  public hideTour: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public toursDisabled = true;

  @ViewChild('scpInbox', { static: false, read: MatMenuTrigger })
  private inbox: MatMenuTrigger;

  @ViewChild('bell', { static: false, read: ElementRef })
  private bell: ElementRef<HTMLDivElement>;

  protected siteName: string;
  protected normalizedSiteName: string;
  protected isAuthorized = false;
  protected chats: SupportChatConfig[] = [];
  protected loading = false;
  protected messages: InboxMessage[];
  protected liveChatConfig: ChatConfig;
  protected zendeskChatConfig: ChatConfig;
  protected zendesk: boolean = false;
  protected zendeskUnreadMessages: number = 0;
  protected zendeskToken: string;
  protected isSupportAvailable: boolean = false;

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public readonly inboxService: InboxService,
    public readonly configService: ConfigService,
    public readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly layoutService: LayoutService,
    private readonly router: Router,
    private readonly scpTourService: ScpTourService,
    private readonly idle: Idle,
    private readonly zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly renderer: Renderer2,
    private readonly ref: ElementRef,
    private readonly appRef: ApplicationRef,
  ) {
  }

  private set disableInbox(disabled: boolean) {
    this.loading = disabled;
    this.changeDetectorRef.markForCheck();
  }

  private static createSupportChatConfig(name: string, config: ChatConfig, width: number = 24, height: number = 24) {
    return {
      icon: name.toLowerCase().replace('_', '-'),
      width,
      height,
      name,
      class: 'scp-header__' + name.toLowerCase(),
      config,
    };
  }

  public ngOnInit(): void {
    this.siteName = this.configService.siteName;
    this.normalizedSiteName = this.configService.normalizedSiteName;
    this.authenticationService.isAuthorized().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.isAuthorized = value;
      this.updateChatMode();
      this.changeDetectorRef.markForCheck();
    });
    this.authenticationService.authorization.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.isAuthorized = value;
      this.updateChatMode();
      this.changeDetectorRef.markForCheck();
    });
    for (const config of this.configService.supportChats) {
      // eslint-disable-next-line no-underscore-dangle
      switch (config.__component) {
        case 'support-chats.telegram': {
          this.chats.push(HeaderComponent.createSupportChatConfig('TELEGRAM', config));
          break;
        }
        case 'support-chats.whats-app': {
          this.chats.push(HeaderComponent.createSupportChatConfig('WHATSAPP', config, 32, 32));
          break;
        }
        case 'support-chats.live-chat': {
          this.chats.push(HeaderComponent.createSupportChatConfig('LIVE_CHAT', config));
          this.liveChatConfig = config;
          break;
        }
        /* istanbul ignore next */
        case 'support-chats.zendesk': {
          this.chats.push(HeaderComponent.createSupportChatConfig('ZENDESK', config));
          this.zendeskChatConfig = config;
          this.updateChatMode();
          this.createZendeskChat(config);
          break;
        }
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.customer) {
      this.updateChatMode();
    }
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      /* istanbul ignore next */
      this.idle.requestIdleCallback(() => {
        this.zone.runOutsideAngular(() => {
          interval(2500).pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe(() => this.bell?.nativeElement.classList.toggle('scp-header__bell__has_animation'));
        });
      });
    }
  }

  public rememberLink(): void {
    if (this.router.url !== '/sign-in') {
      this.authenticationService.setInterruptedUrl(this.router.url);
    }
  }

  public showInbox(): void {
    this.loading = true;
    this.inboxService.messages
      .pipe(takeUntil(this.inbox.menuClosed), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (messages: InboxMessage[]) => {
          this.messages = messages;
          this.disableInbox = false;
        }, error: () => this.disableInbox = false,
      });
  }

  public async showInboxMessage(message: InboxMessage): Promise<void> {
    if (message.bonusProgramOffer) {
      await this.router.navigateByUrl('/account/bonus-programs-offers/' + message.bonusProgramOffer.bonusProgramOfferId);
    } else {
      await this.router.navigateByUrl('/promotions?id=' + message.promotion.promotionId);
    }
  }

  /* istanbul ignore next */
  public async showSupportChat(chat?: SupportChatConfig): Promise<void> {
    if (!chat && this.chats.length === 1) {
      chat = this.chats[0];
    }
    if (chat) {
      switch (chat.name) {
        case 'TELEGRAM':
        case 'WHATSAPP': {
          return window.open(chat.config.url.replace('<type>', this.layoutService.isDesktop ? 'web' : 'api'), '_blank').focus();
        }
        case 'ZENDESK': {
          // eslint-disable-next-line no-underscore-dangle
          this.initZendeskSupportChat(this.configService.supportChats.find(c => c.__component === 'support-chats.zendesk'), true);
          break;
        }
      }
    }
  }

  /* istanbul ignore next */
  private createZendeskChat(config: ChatConfig) {
    if (isPlatformBrowser(this.platformId)) {
      this.appRef.isStable
        .pipe(first((isStable: boolean) => isStable === true), delay(10000), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.idle.requestIdleCallback(
            () => this.zone.runOutsideAngular(
              () => this.initZendeskSupportChat(config, false),
            ),
          ),
        );
    }
  }

  private updateChatMode(): void {
    this.isSupportAvailable = isOpenMode(this.isAuthorized, this.customer?.valueSegment, this.zendeskChatConfig?.settings);
  }

  /* istanbul ignore next */
  private initZendeskSupportChat(config: ChatConfig, open: boolean) {
    if (!this.zendesk) {
      let isFirstChange = true;
      this.zendesk = true;
      const script = this.renderer.createElement('script');
      script.id = 'ze-snippet';
      script.type = 'text/javascript';
      script.src = config.url;
      script.async = true;
      script.onload = () => {
        const zE = global.zE;
        if (!zE) {
          return;
        }
        zE('messenger:set', 'zIndex', 100);
        zE('messenger:set', 'cookies', true);
        zE('messenger:on', 'unreadMessages', (count: number) => {
          this.zendeskUnreadMessages = count;
          this.zone.run(() => this.changeDetectorRef.markForCheck());
        });
        this.storageService.getZendeskToken().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: string) => {
          this.zendeskToken = value;
          if (value) {
            global.zE('messenger', 'loginUser', (callback) => callback(this.zendeskToken));
          } else if (!isFirstChange) {
            global.zE('messenger', 'logoutUser');
          }
          if (isFirstChange && open) {
            isFirstChange = false;
            global.zE('messenger', 'open');
          }
        });
      };
      this.renderer.appendChild(this.ref.nativeElement, script);
    } else if (open && global.zE) {
      global.zE('messenger', 'open');
    }
  }

}
